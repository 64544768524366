import React, { useEffect, useState, useRef } from "react";
import { useNavigate } from "react-router-dom";
import "./InferenceFireScreen.css";
import axios from "axios";
import Loading from "./common/Loading.js";

const InferenceFireScreen = () => {
  const navigate = useNavigate();

  const [items, setItems] = useState([]);
  const [imagePreview, setImagePreview] = useState(null);
  const [imageFile, setImageFile] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const canvasRef = useRef(null);

  const handleImageUpload = (event) => {
    const file = event.target.files[0];
    const reader = new FileReader();
    setImageFile(file);
    reader.onloadend = () => {
      setImagePreview(reader.result);
      setItems([]);
    };

    if (file) {
      reader.readAsDataURL(file);
    }
  };

  useEffect(() => {
    if (imagePreview) 
    {
      const canvas = canvasRef.current;
      const ctx = canvas.getContext("2d");

      const image = new Image();
      image.src = imagePreview;

      image.onload = () => {
        canvas.width = image.width;
        canvas.height = image.height;

        let inW = image.width;
        let inH = image.height;
        let reCanW = image.width;
        let reCanH = image.height;
        let imgW;
        let imgH;
        let ratioW = reCanW / inW;
        let ratioH = reCanH / inH;
        if (reCanW >= inW) {
          if (reCanH >= inH) {
            imgW = inW;
            imgH = inH;
          } else {
            imgW = inW * ratioH;
            imgH = reCanH;
          }
        } else {
          if (reCanH >= inH) {
            imgW = reCanW;
            imgH = inH * ratioW;
          } else {
            if (ratioW > ratioH) {
              imgW = inW * ratioH;
              imgH = inH * ratioH;
            } else {
              imgW = inW * ratioW;
              imgH = inH * ratioW;
            }
          }
        }

        ctx.drawImage(image, 0, 0, imgW, imgH);
        ctx.strokeStyle = "red";
        ctx.lineWidth = imgW/150;
        for(let idx=0; idx<items.length; idx++)
        {
          ctx.strokeRect(items[idx].area.x, items[idx].area.y, items[idx].area.width, items[idx].area.height);
        }
      };
    }
  }, [items, imagePreview]);

  const handleRunRecognition = async () => {
    const token = localStorage.getItem("token");
    //console.log("token : " + token);
    if(token === "" || token == null){
      alert("로그인이 필요한 기능입니다.")
      navigate("/login");
    }
    else
    {
      if (!imagePreview) {
        alert("Please upload an image first.");
        return;
      }
      setIsLoading(true);
      try {
        const formData = new FormData();
        formData.append("images", imageFile);
        // localhost : http://localhost:8080/inference/lpr-demo
        // backend : https://apiback.neowine.com/inference/lpr-demo
        const url = "https://apiback.neowine.com/inference/fire-demo";
        axios.defaults.withCredentials = true;
        axios
          .post(url, formData, {
            withCredentials: true,
            headers: {
              Authorization: `Bearer ${token}`,
            },
          })
          .then(function (response) {
            //console.log(response);

            if(response.data.length === 0)
            {
              alert("이미지 내 인식된 화염/연기 영역이 존재하지 않습니다.");
              setIsLoading(false);
            }
            else
            {
              var items = [];

              for(var idx=0; idx<response.data.length; idx++)
              {
                const plate = response.data[idx].text;
                const area = response.data[idx].area;
                const type = response.data[idx].type;
                var type_class = "";
                if(type==="승용차"){
                  type_class = "passenger";
                }
                if(type==="친환경"){
                  type_class = "eco";
                }
                if(type==="영업용"){
                  type_class = "business";
                }
                if(type==="오토바이"){
                  type_class = "bike";
                }
                if(type==="화물"){
                  type_class = "truck";
                }

                var item = {
                  index:idx+1,
                  area: {
                    x: area.x,
                    y: area.y,
                    width: area.width,
                    height: area.height
                  },
                  type: type,
                  typeclass: type_class,
                  lpnum: plate
                }

                items.push(item);
                setIsLoading(false);
              }
              setItems(items);
            }
          })
          .catch(function (error) {
            setIsLoading(false);
            console.error("Error:", error);
            alert("화염/연기 인식에 실패했습니다. (에러코드 : " + error.response.status +")");
          });
      } catch (error) {
        console.error("Error:", error);
        alert("화염/연기 인식에 실패했습니다. (에러코드 : " + error.response.status +")");
        setIsLoading(false);
      }
    }
  };

  return (
    <div className="page-container">
      <div className="left-section">
        <div className="upload-page">
          <h2>화염/연기 인식 API 데모</h2>
          <div className="image-preview-container">
            {imagePreview ? (
              <canvas ref={canvasRef} className="image-preview" />
            ) : (
              <div className="skeleton"></div>
            )}
          </div>
          <div className="button-container">
            <label htmlFor="file-upload" className="upload-button">
              이미지 불러오기
            </label>
            <input
              id="file-upload"
              type="file"
              accept="image/*"
              onChange={handleImageUpload}
              style={{ display: "none" }}
            />

            <button
              button={isLoading}
              className="run-button"
              onClick={handleRunRecognition}
              disabled={isLoading}>
              <div className="loading" style={{fontSize: 15}}>
                {isLoading ? <Loading /> : "화염/연기 인식 실행"}
              </div>
            </button>
          </div>
        </div>
      </div>
      <div className="right-section">
        <div className="result-box" id="type-result">
          <h3>화염/연기 인식 결과</h3>
          {
            items.length > 0 && items.map((item) => 
            <div className="result-list">
              <p className="item-num">{item.index}.</p>
              <p className={'item-type ' + item.typeclass}>{item.type}</p>
              <p className="item-lpnum">{item.lpnum}</p>
            </div>
            )
          }
        </div>
      </div>
    </div>
  );
};

export default InferenceFireScreen;
