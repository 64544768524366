import "./App.css";
import PricingScreen from "../PricingScreen";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { useState, useEffect } from "react";
import LoginScreen from "../LoginScreen";
import MainScreen from "../MainScreen";
import DocumentScreen from "../DocumentScreen";
import React from "react";
import Header from "./Header";
import Footer from "./Footer";
import InferenceLPRScreen from "../InferenceLPRScreen";
import InferenceFireScreen from "../InferenceFireScreen";
import RedirectPage from "../RedirectPage";
import MyPageScreen from "../MyPageScreen";
import ContactUsScreen from "../ContactusScreen";
import useDeviceSize from "../hooks/useDeviceSize";

export const API_BASE_URL = process.env.REACT_APP_API_ROOT;

function App() {
  useEffect(() => {
    document.title = 'NEOAI OpenAPI';
  }, []);

  const [loginState, changeLogin] = useState(false);
  const [loginType, changeType] = useState("");
  const { isDesktop, isMobile, isTablet } = useDeviceSize();

  const changeLoginState = (state) => {
    if(state === true)
      changeLogin(true);
    else
      changeLogin(false);
  };

  const changeLoginType = (type) => {
    changeType(type);
  }

  return (
    <Router>
        <Header loginState={loginState} loginType={loginType} onChangeLogintState={changeLoginState} onChangeLoginType={changeLoginType}/>
        <Routes>
          <Route path="/" element={<MainScreen/>} />
          <Route path="/contact" element={<ContactUsScreen/>} />
          <Route path="/document" element={<DocumentScreen />} />
          <Route path="/pricing" element={<PricingScreen />} />
          <Route path="/login" element={<LoginScreen loginState={loginState} onChangeLogintState={changeLoginState} onChangeLoginType={changeLoginType}/>} />
          <Route path="/mypage" element={<MyPageScreen onChangeLogintState={changeLoginState} onChangeLoginType={changeLoginType} />} />
          <Route path="/inference-lpr" element={<InferenceLPRScreen onChangeLogintState={changeLoginState} onChangeLoginType={changeLoginType} />}/>
          <Route path="/inference-fire" element={<InferenceFireScreen />} />
          <Route path="/oauth2/redirect" element={<RedirectPage onChangeLogintState={changeLoginState} onChangeLoginType={changeLoginType}/>} />
        </Routes>
        <Footer />
    </Router>
  );
}

export default App;
