import "./DocumentScreen.css";

export default function DocumentScreen () {

    return (
        <div className="document-container">
            {/* 번호판 인식 API Document Start */}
            <div className="solution-container">
                <div className="solution-title">
                    <p className="title-text">번호판 인식 API</p>
                    <p className="title-sub-text">번호판 인식 API 사용을 위한 API 호출 안내 문서입니다.</p>
                </div>
                <div className="solution-content">
                    <p className="content-text">Parameters</p>
                    <p className="title-sub-text">번호판 인식 API 사용 시 필요한 파라미터 정보들을 설명합니다.</p>
                    <table>
                        <thead>
                            <tr>
                                <th scope="col">구분</th>
                                <th scope="col">KeyName</th>
                                <th scope="col">Data Type</th>
                                <th scope="col">필수여부</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>Header</td>
                                <td>apiKey</td>
                                <td>String</td>
                                <td>O</td>
                            </tr>
                            <tr>
                                <td>Data</td>
                                <td>images</td>
                                <td>Multipart/form-data</td>
                                <td>O</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div className="solution-content">
                    <p className="content-text">API URL</p>
                    <p className="title-sub-text">번호판 인식 API 사용 시 필요한 파라미터 정보들을 설명합니다.</p>
                    <table>
                        <thead>
                            <tr>
                                <th scope="col">URL</th>
                                <th scope="col">Method</th>
                                <th scope="col">Response Type</th>
                                <th scope="col">설명</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>https://neowine-backend.com/inference/lpr</td>
                                <td>POST</td>
                                <td>JSON</td>
                                <td>번호판 인식 요청에 따른 결과를 반환합니다.</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div className="solution-content">
                    <p className="content-text">Response Codes</p>
                    <p className="title-sub-text">번호판 인식 API 호출 시 응답받을 수 있는 코드들을 설명합니다.</p>
                    <table>
                        <thead>
                            <tr>
                                <th scope="col">Code</th>
                                <th scope="col">설명</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>200</td>
                                <td>정상응답</td>
                            </tr>
                            <tr>
                                <td>202</td>
                                <td>추론 횟수 소진</td>
                            </tr>
                            <tr>
                                <td>400</td>
                                <td>잘못된 파라미터값 입력</td>
                            </tr>
                            <tr>
                                <td>401</td>
                                <td>유효하지않은 API Key값 입력</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div className="solution-content">
                    <p className="content-text">Response Example</p>
                    <p className="title-sub-text">번호판 인식 API 사용에 따른 응답 값 예시를 설명합니다.</p>
                    <table>
                        <thead>
                            <tr>
                                <th scope="col">key 값</th>
                                <th scope="col">Data Type</th>
                                <th scope="col">설명</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>area</td>
                                <td>Int</td>
                                <td>번호판 영역의 시작점 x,y좌표와 width, height정보를 포함하고 있습니다.</td>
                            </tr>
                            <tr>
                                <td>conf</td>
                                <td>Float</td>
                                <td>번호판 영역의 검출 정확도를 나타냅니다. 값이 클수록 번호판일 확률이 높음을 나타냅니다.</td>
                            </tr>
                            <tr>
                                <td>text</td>
                                <td>String</td>
                                <td>번호판 문자인식 결과 값을 포함하고 있습니다.</td>
                            </tr>
                            <tr>
                                <td>type</td>
                                <td>String</td>
                                <td>번호판 종류에 대한 값을 포함하고 있습니다.</td>
                            </tr>
                        </tbody>
                    </table>
                    <div className="example-code">
                        <pre>
                        {`
    [
        [
            {
                "area": {
                    "x": 0,
                    "y": 0,
                    "width": 0,
                    "height": 0,
                },
                "conf": {
                    "plate": 0
                },
                "text": "00가0000",
                "type": "승용차"
            }
        ]
    ]
                        `}
                        </pre>
                    </div>
                </div>
            </div>
            {/* 번호판 인식 API Document End */}

            {/* 불꽃/연기 검출 API Document Start */}
            <div className="solution-container">
                <div className="solution-title">
                    <p className="title-text">불꽃/연기 검출 API</p>
                    <p className="title-sub-text">불꽃/연기 검출 API 사용을 위한 API 호출 안내 문서입니다.</p>
                </div>
                <div className="solution-content">
                    <p className="content-text">Parameters</p>
                    <p className="title-sub-text">불꽃/연기 검출 API 사용 시 필요한 파라미터 정보들을 설명합니다.</p>
                    <table>
                        <thead>
                            <tr>
                                <th scope="col">Type</th>
                                <th scope="col">KeyName</th>
                                <th scope="col">Content Type</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td colSpan="3">준비중입니다.</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div className="solution-content">
                    <p className="content-text">API URL</p>
                    <p className="title-sub-text">불꽃/연기 검출 API 사용 시 필요한 파라미터 정보들을 설명합니다.</p>
                    <table>
                        <thead>
                            <tr>
                                <th scope="col">URL</th>
                                <th scope="col">Method</th>
                                <th scope="col">Response Type</th>
                                <th scope="col">설명</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td colSpan="4">준비중입니다.</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div className="solution-content">
                    <p className="content-text">Response Codes</p>
                    <p className="title-sub-text">불꽃/연기 검출 API 호출 시 응답받을 수 있는 코드들을 설명합니다.</p>
                    <table>
                        <thead>
                            <tr>
                                <th scope="col">Code</th>
                                <th scope="col">설명</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td colSpan="2">준비중입니다.</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div className="solution-content">
                    <p className="content-text">Response Example</p>
                    <p className="title-sub-text">불꽃/연기 검출 API 사용에 따른 응답 값 예시를 설명합니다.</p>
                    <table>
                        <thead>
                            <tr>
                                <th scope="col">key 값</th>
                                <th scope="col">Data Type</th>
                                <th scope="col">설명</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td colSpan="3">준비중입니다.</td>
                            </tr>
                        </tbody>
                    </table>
                    <div className="example-code">
                        <pre>
                        {`
    서비스 준비중입니다..
                        `}
                        </pre>
                    </div>
                </div>
            </div>
            {/* 불꽃/연기 검출 API Document End */}

            {/* 유사도 분석 API Document Start */}
            <div className="solution-container">
                <div className="solution-title">
                    <p className="title-text">유사도 분석 API</p>
                    <p className="title-sub-text">유사도 분석 API 사용을 위한 API 호출 안내 문서입니다.</p>
                </div>
                <div className="solution-content">
                    <p className="content-text">Parameters</p>
                    <p className="title-sub-text">유사도 분석 API 사용 시 필요한 파라미터 정보들을 설명합니다.</p>
                    <table>
                        <thead>
                            <tr>
                                <th scope="col">Type</th>
                                <th scope="col">KeyName</th>
                                <th scope="col">Content Type</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td colSpan="3">준비중입니다.</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div className="solution-content">
                    <p className="content-text">API URL</p>
                    <p className="title-sub-text">유사도 분석 API 사용 시 필요한 파라미터 정보들을 설명합니다.</p>
                    <table>
                        <thead>
                            <tr>
                                <th scope="col">URL</th>
                                <th scope="col">Method</th>
                                <th scope="col">Response Type</th>
                                <th scope="col">설명</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td colSpan="4">준비중입니다.</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div className="solution-content">
                    <p className="content-text">Response Codes</p>
                    <p className="title-sub-text">유사도 분석 API 호출 시 응답받을 수 있는 코드들을 설명합니다.</p>
                    <table>
                        <thead>
                            <tr>
                                <th scope="col">Code</th>
                                <th scope="col">설명</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td colSpan="2">준비중입니다.</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div className="solution-content">
                    <p className="content-text">Response Example</p>
                    <p className="title-sub-text">유사도 분석 API 사용에 따른 응답 값 예시를 설명합니다.</p>
                    <table>
                        <thead>
                            <tr>
                                <th scope="col">key 값</th>
                                <th scope="col">Data Type</th>
                                <th scope="col">설명</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td colSpan="3">준비중입니다.</td>
                            </tr>
                        </tbody>
                    </table>
                    <div className="example-code">
                        <pre>
                        {`
    서비스 준비중입니다..
                        `}
                        </pre>
                    </div>
                </div>
            </div>
            {/* 불꽃/연기 검출 API Document End */}
        </div>
    );
}