import React from "react";
import useDeviceSize from "../hooks/useDeviceSize";
import { Link } from "react-router-dom";
import { useNavigate, useLocation } from 'react-router-dom';
import { useState, useRef } from "react";
import { googleLogout } from "@react-oauth/google";
import Dropdown from "../Dropdown";

import "./Header.css";
import { Simulate } from "react-dom/test-utils";

export default function Header (props) {
  const location = useLocation();
  const slidemenu = useRef();

  // ----------------------- Dropdown state start ------------------------
  const [isDropdownVisible, setDropdownVisible] = useState(false);
  const { isDesktop, isMobile, isTablet } = useDeviceSize();

  const isMain = () => {
    if(location.pathname === "/"){
      return true;
    } else {
      return false;
    }
  }

  const handleMouseEnter = () => {
    if(!isDropdownVisible)
      setDropdownVisible(true);
  };
  const handleMouseLeave = () => {
    setDropdownVisible(false);
  };
  // ------------------------ Dropdown state end ------------------------
  
  const navigate = useNavigate();

  const logout = () => {
    if(props.loginState === true){
      if(props.loginType === "google"){
        googleLogout();
        props.onChangeLogintState(false);
        props.onChangeLoginType("");
        localStorage.removeItem("token");
        alert("로그아웃 되었습니다");
        navigate("/login");
      }
    }
    if(isTablet || isMobile){
      closeMenu();
    }
  }

  const closeMenu = () => {
      slidemenu.current.click();
  }

  const isSignedIn = () => {
    if(!props.loginState)
    {
      if(isDesktop)
      {
        return (
          <div className="menu-item">
            <Link id="login_link" to="/login" className="menu-link">
              Login
            </Link>
          </div>
        );
      }
      if(isMobile || isTablet)
      {
        return(
          <li>
            <Link to="/login" onClick={() => closeMenu()}>
              Login
            </Link>
          </li>
        ); 
      }
    }
    else
    {
      if(isDesktop)
      {
        return (
          <><div className="menu-item">
            <Link id="login_link" onClick={() => logout()} className="menu-link">
              Logout
            </Link>
          </div><div className="menu-item">
              <Link to="/mypage" className="menu-link">
                MyPage
              </Link>
          </div></>
        );
      }
      if(isMobile || isTablet)
      {
        return(
          <>
          <li>
            <Link onClick={() => logout()}>
              Logout
            </Link>
          </li>
          <li>
            <Link to="/mypage" onClick={() => closeMenu()}>
              Mypage
            </Link>
          </li>
          </>
        ); 
      }
    }
  }

  if(isDesktop)
  {
    return(
      <header>
        <nav>
        <div className="container">
          <div className="menu-item">
            <Link to="/" className="home-link">
              <h2>NeoAI</h2>
            </Link>
          </div>
          <div className="header-menu"> 
            <div className="menu-item">
              <Link to="/contact" className="menu-link contactus">
                Contact Us
              </Link>
            </div>
            <div className="menu-item">
              <Link to="/document" className="menu-link">
                Documents
              </Link>
            </div>
            <div className="menu-item" onMouseEnter={handleMouseEnter}
                  onMouseLeave={handleMouseLeave}>
              <div className="menu-link">
                Demo
              </div>
              {isDropdownVisible && <Dropdown isMain={isMain()}/>}
            </div>
            <div className="menu-item">
              <Link to="/pricing" className="menu-link">
                Pricing
              </Link>
            </div>
            {isSignedIn()}
          </div>
        </div>
        </nav>
      </header>
    );
  }

  if(isMobile || isTablet)
  {
    return(
      <header>
        <nav>
          <div class="content">
            <nav role="navigation">
              <div id="menuToggle" >
                <input type="checkbox" ref={slidemenu}/>
                  <span></span>
                  <span></span>
                  <span></span>
                <ul id="menu">
                  {isSignedIn()}
                  <li><Link to="/contact" onClick={() => closeMenu()}>Contact Us</Link></li>
                  <li><Link to="/inference-lpr" onClick={() => closeMenu()}>Demo - 번호판</Link></li>
                  <li><a href="#" onClick={()=>{alert("준비중입니다.");}}>Demo - 화재/연기</a></li>
                  <li><a href="#" onClick={()=>{alert("준비중입니다.");}}>Demo - 유사도</a></li>
                </ul>
              </div>
              <div className="menu-item">
                <Link to="/" className="home-link">
                  <h3>NeoAI</h3>
                </Link>
              </div>
            </nav>
          </div>
        </nav>
      </header>
    );
  } 
}

