import React, { useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";

export default function RedirectPage (props) {
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const token = params.get("token");

    if (token) {
      // Save token to local storage
      localStorage.setItem("token", token);
      props.onChangeLogintState(true);
      props.onChangeLoginType("google");
      //console.log("login success : " + token);

      // Redirect to /inference
      navigate("/");
    } else {
      // Handle case where token is not present
      console.error("Token not found in URL parameters");
    }
  }, [navigate, location.search, props]);

  return (
    <div>
      <p>Redirecting...</p>
    </div>
  );
};
