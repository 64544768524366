// Footer.js
import React from "react";
import { Link } from "react-router-dom";
import useDeviceSize from "../hooks/useDeviceSize";
import "./Footer.css";

export default function Footer() {

  const { isDesktop, isMobile, isTablet } = useDeviceSize();

  if(isDesktop)
  {
    return (
      <div className="footer">
        <div className="footer-left">
          <Link to="https://neowine.com" target="_blank" rel="noopener noreferrer" className="menu-link-footer">
            <h1>NEOWINE</h1>
          </Link>
        </div>
        <div className="footer-right">
          <div className="footer-line contact-info">
            <p>
              <b>회사명</b> 네오와인
            </p>
            <p>
              <b>대표</b> 이효승
            </p>
            <p>
              <b>주소</b> 경기도 성남시 분당구 백현로 97 (수내동 22-3) 1206호
            </p>
          </div>
          <div className="footer-line">
            <p>
              <b>전화</b> 031-706-8484
            </p>
            <p>
              <b>팩스</b> 031-706-8485
            </p>
            <p>
              <b>사업문의</b> dexter@neowine.com
            </p>
          </div>
          <div className="footer-line">
            <p>Copyright © 2024 NEOWINE. All rights reserved.</p>
          </div>
        </div>
      </div>
    );
  }

  if(isTablet || isMobile)
  {
    return (
      <div className="footer">
        <div className="footer-left">
          <Link to="https://neowine.com" target="_blank" rel="noopener noreferrer" className="menu-link-footer">
            <h1>NEOWINE</h1>
          </Link>
        </div>
        <div className="footer-right">
          <div className="footer-line contact-info">
            <p>
              <b>회사명</b> 네오와인
            </p>
            <p>
              <b>대표</b> 이효승
            </p>
          </div>
          <div className="footer-line">
            <p>
              <b>주소</b> 경기 성남시 분당구 백현로 97 1206호
            </p>
          </div>
          <div className="footer-line">
            <p>
              <b>전화</b> 031-706-8484
            </p>
            <p>
              <b>팩스</b> 031-706-8485
            </p>
          </div>
          <div className="footer-line">
            <p>
              <b>사업문의</b> dexter@neowine.com
            </p>
          </div>
          <div className="footer-line">
            <p>Copyright © 2024 NEOWINE. All rights reserved.</p>
          </div>
        </div>
      </div>
    );
  }
};