import "./LoginScreen.css";
import useDeviceSize from "./hooks/useDeviceSize";
import React, { useEffect } from "react";
import Checkbox from "./common/Checkbox";
import googleLogo from "./assets/google.png";
import naverLogo from "./assets/naver.png";
import kakaoLogo from "./assets/kakao.png";
import TermsText from "./assets/terms.txt";

export const API_BASE_URL = process.env.REACT_APP_API_ROOT;

export default function LoginScreen (props) {

  const [service, setService] = React.useState(false);
  const [termstext, setTermsText] = React.useState();
  const { isDesktop, isMobile, isTablet } = useDeviceSize();

  const Terms = async() => {
    const response = await fetch(TermsText);
    const text = await response.text();
    setTermsText(text);
  }

  useEffect(() => {
    Terms();
  }, []);

  const google = {
    minWidth: "300px",
    borderWidth:"1px",
    borderStyle:"solid",
  };

  const kakao = {
    backgroundColor: "#FFEB02",
    minWidth: "300px",
  };

  const naver = {
    backgroundColor: "#03C75A",
    color: "white",
    minWidth: "300px",
  };

  const loginKakao = () => {
    alert("현재 미지원 로그인방식입니다.");
  };

  const loginNaver = () => {
    alert("현재 미지원 로그인방식입니다.");
  };
  
  if(isDesktop){
    return(
      <div className="content-container">
        <div className="login-container">
          <div className="login-form">
            {/* Your ID and password input login form goes here */}
            <h3 className="social-login-heading">이용약관</h3>
            <div className="terms-container">
              <textarea className="terms-area" defaultValue={termstext}>
              </textarea>
            </div>
            <Checkbox checked={service} onChange={setService}>
              (필수) 서비스 이용약관
            </Checkbox>
          </div>
          <div className="divider"></div>
          <div className="login-links">
            <h3 className="social-login-heading">소셜 로그인</h3>
            {/* localhost : "http://localhost:8080/oauth2/authorize/google?redirect_uri=http://localhost:3000/oauth2/redirect" */}
            {/* backend : "https://apiback.neowine.com/oauth2/authorize/google?redirect_uri=https://api.neowine.com/oauth2/redirect" */}
            <a className="login-link" href={service ? "https://apiback.neowine.com/oauth2/authorize/google?redirect_uri=https://api.neowine.com/oauth2/redirect" : "#"} onClick={service ? e=> e.preventDefault : e => {alert("약관을 체크해주세요.");}} style={google}>
              <img src={googleLogo} alt="google_logo"></img>
              구글 로그인
            </a>
            <a className="login-link" href="#!" onClick={() => loginKakao()} style={kakao}>
              <img src={kakaoLogo} alt="kakao_logo"></img>
              카카오 로그인
            </a>
            <a className="login-link" href="#!" onClick={() => loginNaver()} style={naver}>
              <img src={naverLogo} alt="naver_logo"/>
              네이버 로그인
            </a>
          </div>
        </div>
      </div>
    );
  }

  if(isTablet || isMobile){
    return(
      <div className="content-container">
        <div className="login-container">
          <div className="login-form">
            {/* Your ID and password input login form goes here */}
            <h3 className="social-login-heading">이용약관</h3>
            <div className="terms-container">
              <textarea className="terms-area" defaultValue={termstext}>
              </textarea>
            </div>
            <Checkbox checked={service} onChange={setService}>
              (필수) 서비스 이용약관
            </Checkbox>
          </div>
          <div className="divider"></div>
          <div className="login-links">
            <h3 className="social-login-heading">소셜 로그인</h3>
            {/* localhost : "http://localhost:8080/oauth2/authorize/google?redirect_uri=http://localhost:3000/oauth2/redirect" */}
            {/* backend : "https://apiback.neowine.com/oauth2/authorize/google?redirect_uri=https://api.neowine.com/oauth2/redirect" */}
            <a className="login-link" href={service ? "https://apiback.neowine.com/oauth2/authorize/google?redirect_uri=https://api.neowine.com/oauth2/redirect" : "#"} onClick={service ? e=> e.preventDefault : e => {alert("약관을 체크해주세요.");}} style={google}>
              <img src={googleLogo} alt="google_logo"></img>
              구글 로그인
            </a>
            <a className="login-link" href="#!" onClick={() => loginKakao()} style={kakao}>
              <img src={kakaoLogo} alt="kakao_logo"></img>
              카카오 로그인
            </a>
            <a className="login-link" href="#!" onClick={() => loginNaver()}style={naver}>
              <img src={naverLogo} alt="naver_logo"/>
              네이버 로그인
            </a>
          </div>
        </div>
      </div>
    );
  }
}
