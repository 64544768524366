import "./ContactusScreen.css";
import useDeviceSize from "./hooks/useDeviceSize";
import React, { useRef, useEffect } from 'react';
import emailjs from "@emailjs/browser";
import TermsText from "./assets/terms-contact.txt";

export default function ContactusScreen () {
    const form = useRef();
    const [termstext, setTermsText] = React.useState();
    const { isDesktop, isMobile, isTablet } = useDeviceSize();

    const Terms = async() => {
        const response = await fetch(TermsText);
        const text = await response.text();
        setTermsText(text);
    }

    useEffect(() => {
    Terms();
    }, []);

    const sendEmail = (e) => {
        e.preventDefault();

        emailjs.sendForm("service_d9bbuye", "template_gv5jrqd", form.current, "Z0fVCi3S3mSQo_F8Y").then(
            result => {
              alert("성공적으로 이메일이 전송되었습니다.");
              form.current.reset();
            },
            error => {
              alert("이메일 전송이 실패하였습니다.");
            },
          );
    }

    if(isDesktop)
    {
        return (
            <div className="container-contact">
                <form ref={form} onSubmit={sendEmail}>
                <div className="inside-container">
                    <h3 className="service-item">문의 서비스</h3>
                    <div className="select">
                        <select name="product_name">
                            <option>번호판인식 API</option>
                            <option>화염 및 연기인식 API</option>
                            <option>유사도분석 API</option>
                        </select>
                        <div className="select__arrow"></div>
                    </div>
                    <div className="vertically-divided">
                        <div className="group">      
                            <input type="text" name="user_name" required />
                            <span className="highlight"></span>
                            <span className="bar"></span>
                            <label>Name*</label>
                        </div>
                        
                        <div className="group">      
                            <input type="text" name="user_email" required />
                            <span className="highlight"></span>
                            <span className="bar"></span>
                            <label>Email*</label>
                        </div>
                    </div>
                    <div className="vertically-divided">
                        <div className="group">      
                            <input type="text" name="company_name" required />
                            <span className="highlight"></span>
                            <span className="bar"></span>
                            <label>Company*</label>
                        </div>
                        
                        <div className="group">      
                            <input type="text" name="position" required />
                            <span className="highlight"></span>
                            <span className="bar"></span>
                            <label>Position*</label>
                        </div>
                    </div>
                    <textarea name="message" class="feedback-input" id="comment" placeholder="Comment*" maxLength="2000" required >
    
                    </textarea>
    
                    <textarea class="feedback-terms" defaultValue={termstext} readOnly>
                    </textarea>
    
                    <div>
                        <label className="control control--checkbox">개인정보 수집 및 이용안내에 동의합니다.
                            <input type="checkbox" className="custom-input" onChange={(e)=>{/*console.log(e.target.checked)*/}} required />
                            <div className="control__indicator"></div>
                        </label>
                    </div>
                    <button className="submit-button">
                        Submit
                    </button>
                </div>
                </form>
            </div>
        );
    }
    
    if(isTablet || isMobile)
    {
        return (
            <div className="container-contact">
                <form ref={form} onSubmit={sendEmail}>
                <div className="inside-container">
                    <h3 className="service-item">문의 서비스</h3>
                    <div className="select">
                        <select name="product_name">
                            <option>번호판인식 API</option>
                            <option>화염 및 연기인식 API</option>
                            <option>유사도분석 API</option>
                        </select>
                        <div className="select__arrow"></div>
                    </div>
                    <div className="vertically-divided">
                        <div className="group">      
                            <input type="text" name="user_name" required />
                            <span className="highlight"></span>
                            <span className="bar"></span>
                            <label>Name*</label>
                        </div>
                        
                        <div className="group">      
                            <input type="text" name="user_email" required />
                            <span className="highlight"></span>
                            <span className="bar"></span>
                            <label>Email*</label>
                        </div>
                    </div>
                    <div className="vertically-divided">
                        <div className="group">      
                            <input type="text" name="company_name" required />
                            <span className="highlight"></span>
                            <span className="bar"></span>
                            <label>Company*</label>
                        </div>
                        
                        <div className="group">      
                            <input type="text" name="position" required />
                            <span className="highlight"></span>
                            <span className="bar"></span>
                            <label>Position*</label>
                        </div>
                    </div>
                    <textarea name="message" class="feedback-input" id="comment" placeholder="Comment*" maxLength="2000" required >
    
                    </textarea>
    
                    <textarea class="feedback-terms" defaultValue={termstext} readOnly>
                    </textarea>
    
                    <div>
                        <label className="control control--checkbox">개인정보 수집 및 이용안내에 동의합니다.
                            <input type="checkbox" className="custom-input" onChange={(e)=>{/*console.log(e.target.checked)*/}} required />
                            <div className="control__indicator"></div>
                        </label>
                    </div>
                    <button className="submit-button">
                        Submit
                    </button>
                </div>
                </form>
            </div>
        );
    }
}