

import "./Checkbox.css";

export default function Checkbox({ children, disabled, checked, onChange })
{
    return (
        <label className="checkbox-label">
            <input
            type="checkbox"
            disabled={disabled}
            checked={checked}
            onChange={({ target: { checked } }) => onChange(checked)}
            />
            {children}
        </label>
    );
}