import "./MyPageScreen.css";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

export default function MyPageScreen (props) {

    const navigate = useNavigate();

    const [myAPIKey, setApiKey] = useState(null);
    const [lprCount, setLprCount] = useState(null);

    useEffect(() => {
        const token = localStorage.getItem("token");
        if(token === "" || token == null){
            alert("먼저 로그인이 필요합니다.");
            navigate("/login");
        }

        //console.log("token : " + token);
        try {
            // localhost : http://localhost:8080/inference
            // backend : https://apiback.neowine.com/inference
            const url = "https://apiback.neowine.com/api-key";
            axios
              .get(url, {
                headers: {
                  Authorization: `Bearer ${token}`,
                }
              })
              .then(function (response) {
                //console.log(response);
                setApiKey(response.data.value);
                setLprCount(response.data.infCount);
              })
              .catch(function (error) {
                console.error("Error:", error);
                if(error.response.status == 401){
                    alert("토큰 시간이 만료되었습니다. 재로그인 해주세요.");
                    localStorage.removeItem("token");
                    props.onChangeLogintState(false);
                    props.onChangeLoginType("");
                    navigate("/login");
                }
              });
          } catch (error) {
            console.error("Error:", error);
            if(error.response.status == 401){
                alert("토큰 시간이 만료되었습니다. 재로그인 해주세요.");
                localStorage.removeItem("token");
                props.onChangeLogintState(false);
                props.onChangeLoginType("");
                navigate("/login");
            }
          }

      }, [navigate]);
    
    return (
        <div className="container-main">
            <div className="container-apikey">
                <div>
                    <h3 className="social-login-heading">API Key</h3>
                </div>
                <p className="result" id="user-api-key">{myAPIKey || "-"}</p>
            </div>

            <div className="container-apikey">
                <div>
                    <h3 className="social-login-heading">API 잔여 횟수</h3>
                </div>
                <div className="container-apicount">
                    <div className="api-title">번호판 인식 API : </div><p className="api-count" id="lpr-count">{lprCount || "-"}</p>
                </div>
                <div className="container-apicount">
                    <div className="api-title">화염/연기 인식 API : </div><p className="api-count" id="fire-count">-</p>
                </div>
                <div className="container-apicount">
                    <div className="api-title">유사도 비교 API : </div><p className="api-count" id="sim-count">-</p>
                </div>
            </div>
        </div>
    );
}