import "./MainScreen.css";
import useDeviceSize from "./hooks/useDeviceSize";
import ModelCSS from "./common/Modal.css"
import fireVideo from "./resources/video/fire.webm";
import trafficVideo from "./resources/video/traffic.webm"
import similarVideo from "./resources/video/similarity.webm"
import LPRDemoVideo from "./resources/video/lpr-demo.mp4"
import { useNavigate } from 'react-router-dom';
import { useState, useEffect } from "react";
import Modal from "react-modal";
import ReactPlayer from "react-player";

export default function MainScreen () {
    const navigate = useNavigate();
    const [isLPRDemoOpened, openLPRDemoDlg] = useState(false);
    const { isDesktop, isMobile, isTablet } = useDeviceSize();

    // ----------------------------------------------------------------------------------------
    // *************************************** PC Screen **************************************
    // ----------------------------------------------------------------------------------------
    if(isDesktop){
        return (
            <div className="container-main-screen">
                <div className="container-solution-main">
                    <div className="image-background" />
                    <div className="content-solution-main">
                        <div className="title-solution-main">
                            <h2>NeoAI OpenAPI</h2>
                            <p className="detail-solution-main">생성형 AI기반의 학습데이터 보강으로<br />카메라 영상기반 특수상황인식 및 분석 Open API 서비스를 제공합니다.</p>
                        </div>
                    </div>
                </div>
                <div className="container-solution">
                    <div className="video-background">
                        <video className="video-content" autoPlay loop muted>
                            <source src={trafficVideo} type="video/webm" />
                        </video>
                    </div>
                    <div className="content-solution">
                        <div className="title-solution">
                            <div className="title-solution-text"><h2>License Plate Recognition</h2></div>
                            <div className="title-solution-demo-video"><button onClick={() => {openLPRDemoDlg(true)}}>&#x25b6;</button></div>
                            <div className="title-solution-demo"><button onClick={() => {navigate("/inference-lpr")}}>Run Demo</button></div>
                        </div>
                        <div><p className="detail-solution-content">카메라 영상 내 다중 번호판의 문자 인식 기능을 제공합니다.</p></div>
                        <div><p className="detail-solution-content-small">(10m 이내 인식률 99%)</p></div>
                    </div>
                </div>
                <div className="container-solution">
                    <div className="video-background">
                        <video className="video-content" autoPlay loop muted>
                            <source src={fireVideo} type="video/webm" />
                        </video>
                    </div>
                    <div className="content-solution">
                        <div className="title-solution">
                            <div className="title-solution-text"><h2>Fire & Smoke Detection</h2></div>
                            <div className="title-solution-demo"><button onClick={()=>{alert("준비중입니다.")}}>Run Demo</button></div>
                        </div>
                        <div><p className="detail-solution-content">카메라 영상 내 화재 및 연기 검출 기능을 제공합니다.</p></div>
                        <div><p className="detail-solution-content-small">(불꽃 인식률 97% / 연기 인식률 95%)</p></div>
                    </div>
                </div>
                <div className="container-solution">
                    <div className="video-background">
                        <video className="video-content" autoPlay loop muted>
                            <source src={similarVideo} type="video/webm" />
                        </video>
                    </div>
                    <div className="content-solution">
                        <div className="title-solution">
                            <div className="title-solution-text"><h2>Similarity Analysis</h2></div>
                            <div className="title-solution-demo"><button onClick={()=>{alert("준비중입니다.")}}>Run Demo</button></div>
                        </div>
                        <div><p className="detail-solution-content">실제품의 사진과 디자인 도면 이미지에 대한 유사도 분석 기능을 제공합니다.</p></div>
                        <div><p className="detail-solution-content-small">(유사도 비교 정확도 98%)</p></div>
                    </div>
                </div>
                <Modal isOpen={isLPRDemoOpened} style={ModelCSS}>
                    <ReactPlayer
                        url="https://youtu.be/Pd-a70c9x_4?si=PuKlxRxjr3kLB72G"
                        controls={true} 
                        width="100%"
                        height="100%" />
                    <button onClick={()=>{openLPRDemoDlg(false)}}>닫기</button>
                </Modal>
            </div>
        );
    }

    // ----------------------------------------------------------------------------------------------------
    // *************************************** Tablet / Mobile Screen *************************************
    // ----------------------------------------------------------------------------------------------------
    if(isTablet || isMobile)
    {
        return(
            <div className="container-main-screen">
                <div className="container-solution-main">
                    <div className="image-background" />
                    <div className="content-solution-main">
                        <div className="title-solution-main">
                            <h2>NeoAI OpenAPI</h2>
                            <p className="detail-solution-main">생성형 AI기반의 학습데이터 보강으로<br />카메라 영상기반 특수상황인식 및 분석<br />Open API 서비스를 제공합니다.</p>
                        </div>
                    </div>
                </div>
                <div className="container-solution">
                    <div className="video-background">
                        <video className="video-content" playsInline autoPlay loop muted>
                            <source src={trafficVideo} type="video/webm" />
                        </video>
                    </div>
                    <div className="content-solution">
                        <div className="title-solution">
                            <div className="title-solution-text"><h2>License Plate<br />Recognition</h2></div>
                            <div className="title-solution-demo-video">
                                <button onClick={() => {openLPRDemoDlg(true)}}>
                                    <svg width="20" height="20" viewBox="2 2 20 20" fill="white" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M8 5v14l11-7z"/>
                                    </svg>
                                </button>
                            </div>
                            <div className="title-solution-demo"><button onClick={() => {navigate("/inference-lpr")}}>Run Demo</button></div>
                        </div>
                        <div><p className="detail-solution-content">카메라 영상 내 다중 번호판의 문자 인식 기능을 제공합니다.</p></div>
                        <div><p className="detail-solution-content-small">(10m 이내 인식률 99%)</p></div>
                    </div>
                </div>
                <div className="container-solution">
                    <div className="video-background">
                        <video className="video-content" playsInline autoPlay loop muted>
                            <source src={fireVideo} type="video/webm" />
                        </video>
                    </div>
                    <div className="content-solution">
                        <div className="title-solution">
                            <div className="title-solution-text"><h2>Fire & Smoke<br />Detection</h2></div>
                            <div className="title-solution-demo"><button onClick={()=>{alert("준비중입니다.")}}>Run Demo</button></div>
                        </div>
                        <div><p className="detail-solution-content">카메라 영상 내 화재 및 연기 검출 기능을 제공합니다.</p></div>
                        <div><p className="detail-solution-content-small">(불꽃 인식률 97% / 연기 인식률 95%)</p></div>
                    </div>
                </div>
                <div className="container-solution">
                    <div className="video-background">
                        <video className="video-content" playsInline autoPlay loop muted>
                            <source src={similarVideo} type="video/webm" />
                        </video>
                    </div>
                    <div className="content-solution">
                        <div className="title-solution">
                            <div className="title-solution-text"><h2>Similarity<br />Analysis</h2></div>
                            <div className="title-solution-demo"><button onClick={()=>{alert("준비중입니다.")}}>Run Demo</button></div>
                        </div>
                        <div><p className="detail-solution-content">실제품의 사진과 디자인 도면 이미지에 대한 유사도 분석 기능을 제공합니다.</p></div>
                        <div><p className="detail-solution-content-small">(유사도 비교 정확도 98%)</p></div>
                    </div>
                </div>
                <Modal isOpen={isLPRDemoOpened} style={ModelCSS}>
                    <ReactPlayer
                        url="https://youtu.be/Pd-a70c9x_4?si=PuKlxRxjr3kLB72G"
                        controls={true} 
                        width='100%'
                        height='100%' />
                    <button onClick={()=>{openLPRDemoDlg(false)}}>닫기</button>
                </Modal>
            </div>
        );
    }
}