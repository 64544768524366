import React, { useEffect } from "react";
import { useNavigate } from 'react-router-dom';
import axios from "axios";
import "./PricingScreen.css";
import checkIcon from "./assets/check-green.svg";

const HomeScreen = () => {

  const navigate = useNavigate();

  useEffect(() => {
    const jquery = document.createElement("script");
    jquery.src = "https://code.jquery.com/jquery-1.12.4.min.js";
    const iamport = document.createElement("script");
    iamport.src = "https://cdn.iamport.kr/js/iamport.payment-1.1.7.js";
    document.head.appendChild(jquery);
    document.head.appendChild(iamport);
    return () => {
      document.head.removeChild(jquery);
      document.head.removeChild(iamport);
    };
  }, []);

  function onClickPayment_SeriesLPR() {
    navigate("/contact");
    /*const { IMP } = window;
    IMP.init("imp43165276");
    IMP.request_pay(
      {
        pg: "html5_inicis",
        pay_method: "card",
        merchant_uid: "test_1123234",
        name: "테스트 결제",
        amount: 100,
        buyer_tel: "010-0000-0000",
      },
      callback
    );*/
  }

  function onClickPayment_SeriesF() {
    alert("준비중입니다..");
  }

  function onClickPayment_SeriesS() {
    alert("준비중입니다..");
  }

  function callback(response) {
    const { success, imp_uid, merchant_uid, error_msg } = response;
    if (success) {
      alert("결제 성공");
      axios.post("https://apiback.neowine.com/payment/validate", imp_uid);
    } else {
      alert(`결제 실패: ${error_msg}`);
    }
  }

  return (
    <div className="container-payment">

      <div className="item-payment">
        <div className="item-title">
          <h3>번호판 인식 API</h3>
        </div>
        <div className="item-content-container">
          <div className="item-period-professional">
            <span>Professional</span>
          </div>
          <div className="item-content-detail">
            가격문의 <span className="content-partial-font">월간 결제</span>
            <div className="item-content-functions">
              <ul>
                <li>
                  <img src={checkIcon} className="check-icon" alt=""/>
                  <span>매월 50,000건 기본 추론 횟수 제공</span>
                </li>
                <li>
                  <img src={checkIcon} className="check-icon" alt=""/>
                  <span>기본 추론 횟수 초과 시 건당 30원 부과</span>
                </li>
                <li>
                  <img src={checkIcon} className="check-icon" alt=""/>
                  <span>다중 번호판 인식</span>
                </li>
                <li>
                  <img src={checkIcon} className="check-icon" alt=""/>
                  <span>승용차, 영업용, 화물차 번호판 인식</span>
                </li>
              </ul>
            </div>
          </div>
          <div className="item-button">
            <button onClick={onClickPayment_SeriesLPR}>문의하기</button>
          </div>

          <div className="item-period">
            <span>Basic</span>
          </div>
          <div className="item-content-detail">
          가격문의 <span className="content-partial-font">월간 결제</span>
            <div className="item-content-functions">
              <ul>
                <li>
                  <img src={checkIcon} className="check-icon" alt=""/>
                  <span>매월 10,000건 기본 추론 횟수 제공</span>
                </li>
                <li>
                  <img src={checkIcon} className="check-icon" alt=""/>
                  <span>기본 추론 횟수 초과 시 건당 30원 부과</span>
                </li>
                <li>
                  <img src={checkIcon} className="check-icon" alt=""/>
                  <span>단일 번호판 인식</span>
                </li>
                <li>
                  <img src={checkIcon} className="check-icon" alt=""/>
                  <span>승용차, 영업용 차량 번호판 인식</span>
                </li>
              </ul>
            </div>
          </div>
          <div className="item-button">
            <button onClick={onClickPayment_SeriesLPR}>문의하기</button>
          </div>
        </div>
      </div>

      <div className="item-payment">
        <div className="item-title">
          <h3>화재/연기 인식 API</h3>
        </div>
        <div className="item-content-container">
          <div className="item-period-professional">
            <span>Professional</span>
          </div>
          <div className="item-content-detail">
            가격문의 <span className="content-partial-font">월간 결제</span>
            <div className="item-content-functions">
              <ul>
                <li>
                  <img src={checkIcon} className="check-icon" alt=""/>
                  <span>서비스 준비중</span>
                </li>
                <li>
                  <img src={checkIcon} className="check-icon" alt=""/>
                  <span>서비스 준비중</span>
                </li>
                <li>
                  <img src={checkIcon} className="check-icon" alt=""/>
                  <span>서비스 준비중</span>
                </li>
                <li>
                  <img src={checkIcon} className="check-icon" alt=""/>
                  <span>서비스 준비중</span>
                </li>
              </ul>
            </div>
          </div>
          <div className="item-button-ready">
            <button onClick={onClickPayment_SeriesF}>Coming Soon!</button>
          </div>

          <div className="item-period">
            <span>Basic</span>
          </div>
          <div className="item-content-detail">
            가격문의 <span className="content-partial-font">월간 결제</span>
            <div className="item-content-functions">
              <ul>
                <li>
                  <img src={checkIcon} className="check-icon" alt=""/>
                  <span>서비스 준비중</span>
                </li>
                <li>
                  <img src={checkIcon} className="check-icon" alt=""/>
                  <span>서비스 준비중</span>
                </li>
                <li>
                  <img src={checkIcon} className="check-icon" alt=""/>
                  <span>서비스 준비중</span>
                </li>
                <li>
                  <img src={checkIcon} className="check-icon" alt=""/>
                  <span>서비스 준비중</span>
                </li>
              </ul>
            </div>
          </div>
          <div className="item-button-ready">
            <button onClick={onClickPayment_SeriesF}>Coming Soon!</button>
          </div>
        </div>
      </div>

      <div className="item-payment">
        <div className="item-title">
          <h3>유사도 분석 API</h3>
        </div>
        <div className="item-content-container">
          <div className="item-period-professional">
            <span>Professional</span>
          </div>
          <div className="item-content-detail">
            가격문의 <span className="content-partial-font">월간 결제</span>
            <div className="item-content-functions">
              <ul>
                <li>
                  <img src={checkIcon} className="check-icon" alt=""/>
                  <span>서비스 준비중</span>
                </li>
                <li>
                  <img src={checkIcon} className="check-icon" alt=""/>
                  <span>서비스 준비중</span>
                </li>
                <li>
                  <img src={checkIcon} className="check-icon" alt=""/>
                  <span>서비스 준비중</span>
                </li>
                <li>
                  <img src={checkIcon} className="check-icon" alt=""/>
                  <span>서비스 준비중</span>
                </li>
              </ul>
            </div>
          </div>
          <div className="item-button-ready">
            <button onClick={onClickPayment_SeriesS}>Coming Soon!</button>
          </div>

          <div className="item-period">
            <span>Basic</span>
          </div>
          <div className="item-content-detail">
            가격문의 <span className="content-partial-font">월간 결제</span>
            <div className="item-content-functions">
              <ul>
                <li>
                  <img src={checkIcon} className="check-icon" alt=""/>
                  <span>서비스 준비중</span>
                </li>
                <li>
                  <img src={checkIcon} className="check-icon" alt=""/>
                  <span>서비스 준비중</span>
                </li>
                <li>
                  <img src={checkIcon} className="check-icon" alt=""/>
                  <span>서비스 준비중</span>
                </li>
                <li>
                  <img src={checkIcon} className="check-icon" alt=""/>
                  <span>서비스 준비중</span>
                </li>
              </ul>
            </div>
          </div>
          <div className="item-button-ready">
            <button onClick={onClickPayment_SeriesS}>Coming Soon!</button>
          </div>
        </div>
      </div>
    </div>
  );
};
export default HomeScreen;
