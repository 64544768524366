import "./Dropdown.css";
import { useNavigate} from 'react-router-dom';

const Dropdown = (props) => {
  const navigate = useNavigate();

    return (
      <div className={props.isMain ? "dropdown-menu" : "dropdown-menu-not-main"}>
        <ul>
          <li onClick={() => {navigate('/inference-lpr');}} className="dropdown-link">
              번호판 인식 데모
          </li>
          <li onClick={() => {alert("준비중입니다.");}} className="dropdown-link">
              화재/연기 검출 데모
          </li>
          <li onClick={() => {alert("준비중입니다.");}} className="dropdown-link">
              유사도 분석 데모
          </li>
        </ul>
      </div>
    );
  };
  
  export default Dropdown;