import { Circles } from "react-loader-spinner";

const Loading = () => {
  return (
    <Circles
      className="loading"
      type="Oval"
      color="#3d66ba"
      height={20}
      width={20}
      timeout={3000}
    ></Circles>
  );
};

export default Loading;
